import React from 'react'
import Fade from 'react-reveal/Fade'
import { Link as ScrollLink } from 'react-scroll'
import chart from '../assets/images/chart.png'

const FiveTwo = props => (
  <Fade up>
    <section id="fivetwo" className="wrapper style1 special fade-up">
      <div className="container">


<p className="align-left">
To control for day-to-day experimental variations external to the study, data from each experimental condition is normalized against the long-term storage condition measurements on that day.  This should not impact the value of the rate constant, which is calculated as the slope of a linear regression on a Normalized Signal vs. Time plot.   If your data is nonlinear, then you have run out your experiment too long and you should truncate your timepoints to stay within the linear range. </p>
<span className="image fit main">
      <img src={chart} alt="" />
    </span>
<p className="align-left">
Now that you have experimentally determined the rate constant for your experimental temperature (k<sub>exp</sub>), you will apply the Arrhenius equation to determine the rate constant for your long-term storage temperature (k<sub>storage</sub>) </p>
  <p className="align-left">
The Arrhenius equation relates the rate constant (k) to the temperature of the reaction:
</p>
<p className="align-left">
k = Aexp(-E<sub>a</sub>/RT)
</p>
<p className="align-left">
Where A= pre-exponential factor, E<sub>a</sub> = activation energy, R = gas constant = 1.987 cal/deg*mole, and T = Kelvin temperature
</p>
<p className="align-left">
By setting up the ratio of two Arrhenius equations, we can ultimately solve for k<sub>storage</sub>.
</p>
<p className="align-left">
k<sub>exp</sub> / k<sub>storage</sub> = exp(-Ea/R*[1/T<sub>exp</sub> – 1/T<sub>storage</sub>])
</p>
<p className="align-left">
🡪 k<sub>storage</sub> =
</p>
<p className="align-left">
But wait!  We’re still missing the activation energy (Ea), which you can either assume to be an average value or use additional experimental data to calculate.  A typical activation energy value in biology is 15000 cal/mol/K.  If you have more than two experimental temperatures, then you can use a non-exponential form of the Arrhenius equation and the calculated rate constants to determine your activation energy.  The activation energy is solved from a ln(k) vs 1/T plot because the slope is -E<sub>a</sub>/R.
</p>
<p className="align-left">
ln(k) = ln(A) – Ea/(RT)
</p>
<p className="align-left">
With an activation energy value, you can now use the k<sub>storage</sub> to determine how long you have before your performance tests signal degrades by X%:
</p>
<p className="align-left">
k<sub>storage</sub>= Δsignal/Δtime 🡪 shelf-life = Δtime = X/k
</p>
  <p><br/></p>

   <ScrollLink
      to="six"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>

    </div>
    </section>
  </Fade>
)

export default FiveTwo
