import React from 'react'
import Fade from 'react-reveal/Fade'
import { Link as ScrollLink } from 'react-scroll'

const FourTwo = props => (
  <Fade up>
    <section id="fourtwo" className="wrapper style1 special fade-up">
      <div className="container">

      <table className="align-left user-table-size div-inline">
        <tr>
          <td className="user-table-label align-center"><h4>Resource</h4></td>
          <td className="user-table-label align-center"><h4>Consideration</h4></td>
        </tr>
        <tr>
          <td className="user-table-label"><h4>Equipment</h4></td>
          <td className="user-table-label"><p className="align-left">
Check that the equipment used to maintain your samples at the long-term storage and experimental temperatures are within their calibration dates and that you have some redundancy consideration if there is any equipment failure during your study.
</p>
<p className="align-left">
Ensure that the equipment can be left reasonably undisturbed for the duration of your study.  Frequent temperature fluctuations caused by equipment doors opening/closing on shared equipment could harm the quality of your data. If you want to be particularly thorough, use an automated temperature logger to ensure the storage temperatures remain within specification.
</p>
<p className="align-left">
Although humidity control is preferred, if your equipment cannot control humidity, do not disregard the value of the study.  There will be less confidence in your result, but it will still be a meaningful result.
</p></td>
        </tr>
        <tr>
          <td className="user-table-label"><h4>Reagents</h4></td>
          <td className="user-table-label"><p className="align-left">
Gather sufficient samples for all timepoints to be completed at each timepoint.  Don’t forget reagents for the performance tests!
</p>
<p className="align-left">
Remember to include Day 1, replicates, sufficient samples at your long-term storage temperature to run a real-time study, and overage for any schedule changes or deviated test results that need repeating.  I recommend at least 25% overage.
</p></td>
        </tr>
        <tr>
          <td className="user-table-label"><h4>Time</h4></td>
          <td className="user-table-label"><p className="align-left">
Estimate the time required to complete each performance test for all replicates at each of the required temperatures.  Ensure you or someone on your team has sufficient time to complete these studies and puts a reminder in their calendar for each timepoint.
</p>
<p className="align-left">
Stability studies, especially those that include real-time data, can extend beyond someone’s time of employment, so it is important to document and communicate the planned schedule.
</p></td>
        </tr>
      </table>
  <p><br/></p>

   <ScrollLink
      to="five"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>

    </div>
    </section>
  </Fade>
)

export default FourTwo
