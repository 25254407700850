import React from 'react'
import pic02 from '../assets/images/pic02.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const One = props => (
  <section
    id="one"
    className="spotlight style1 bottom inactive"
    style={{ backgroundImage: `url(${pic02})` }}
  >
    <span className="image fit main">
      <img src={pic02} alt="" />
    </span>
    <Fade bottom big>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-4 col-12-medium">
              <header>
                <h2>Why is it important to know reagent shelf life?</h2>
                <p>
                  Reagent shelf life is more broadly useful than telling consumers when to expect their milk to sour.
                </p>
              </header>
            </div>
            <div className="col-4 col-12-medium align-left">
              <p>
                In the biochemical manufacturing and research space, shelf life defines the period over which materials are performing at their prime and what storage temperature maximizes this time.  Additionally, shelf life informs when to discard materials and ultimately feeds into upstream processes to optimize batch sizes (no use making more than you can use before expiry, no use making materials more frequently than needed).
              </p>
            </div>
          </div>
        </div>
        <div className="align-center">
        <div className="div-inline">
        <ul className="actions align-center">
          <li>
            <a href="/conceptualoverview" className="button">
              Conceptual Overview
            </a>
            </li>
        </ul>
        </div>
        </div>


      </div>
    </Fade>
    <ScrollLink
      to="two"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)
export default One
