import React from 'react'
import Fade from 'react-reveal/Fade'
import { Link as ScrollLink } from 'react-scroll'

const ThreeTwo = props => (
  <Fade up>
    <section id="threetwo" className="wrapper style1 special fade-up">
      <div className="container">
        <h4>Determine the frequency of testing:</h4>
        <p className="align-left">
        The most basic study will include a test on Day 0 to measure 100% performance, then a test on the date of expiry to measure exactly when the product no longer performs to minimum specification.  Since it is difficult to know the expiry date ahead of experimentation, especially at accelerated temperatures, you may need to make schedule adjustments while running the study.
        </p>
        <p className="align-left">
        Choose timepoints reasonably concentrated around the expected expiry date at the experimental temperature and then one or two timepoints well past the expiry date.  These later timepoints will be
        </p>
        <p className="align-left">
        For example, if your product is expected to expire in 3 weeks at the experimental (accelerated) storage temperature, then your timepoints could be:
        </p>
        <p className="align-left">
        Day 1, 14, 21, 28, and 42.
        </p>
        <p className="align-left">
        Your experimental plan will grow more complex as you include additional temperatures with other expected expiry dates and include real-time stability testing.
        </p>


      <table className="align-left user-table-size div-inline">
        <tr>
          <td className="user-table">The estimated shelf life at the long-term storage temperature is: </td>
          <td className="user-table"> </td>
        </tr>
        <tr>
          <td className="user-table">The estimated shelf life at each of my experimental storage temperatures are:</td>
          <td className="user-table"> </td>
        </tr>
        <tr>
          <td className="user-table">The timepoints for my study are:</td>
          <td className="user-table"></td>
        </tr>
      </table>

  <p><br/></p>
  <ScrollLink
      to="four"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
    </div>
    </section>
  </Fade>
)

export default ThreeTwo
