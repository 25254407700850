import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import One from '../components/One'
import Two from '../components/Two'
import Three from '../components/Three'
import ThreeTwo from '../components/ThreeTwo'
import Four from '../components/Four'
import FourTwo from '../components/FourTwo'
import Five from '../components/Five'
import FiveTwo from '../components/FiveTwo'
import Six from '../components/Six'

class Home extends React.Component {
  render() {
    return (
      <Layout location="/">
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          title="Mitochandrea"
          meta={[
            { name: 'description', content: 'Mitochandrea' },
            { name: 'keywords', content: 'Mitochandrea' },
          ]}
        ></Helmet>
        <Banner />
        <One />
        <Two />
        <Three />
        <ThreeTwo />
        <Four />
        <FourTwo />
        <Five />
        <FiveTwo />
        <Six />
      </Layout>
    )
  }
}

export default Home
