import React from 'react'
import pic05 from '../assets/images/pic05.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const Five = props => (
  <section
    id="five"
    className="spotlight style2 left inactive"
    style={{ backgroundImage: `url(${pic05})` }}
  >
    <span className="image fit main">
      <img src={pic05} alt="" />
    </span>
    <Fade left big>
      <div className="content">
        <header>
          <h2>Step 4: Set up Your Data Analysis Template</h2>
          <p>Learning the theory behind a stability study not only means you understand, but you will make better decisions when things don’t go according to plan.</p>
        </header>
        <h3>Step 1 Determine Method of Testing</h3>
        <p className="align-left">
          In this experiment, the reaction of interest is the collision and subsequent degradation of your active reagents, as measured by your performance test.  The rate constant for this reaction is referred to as k.  You will use the Arrhenius Equation to relate the rate constant at an experimental (typically elevated) temperature (k<sub>exp</sub>) to the rate constant at a long-term storage temperature (k<sub>storage</sub>) and, ultimately, calculate the expected shelf life at that long-term storage temperature.
          </p>
          <p className="align-left">
        For some, now would be a good time to review the associated Excel template. </p>


      </div>
    </Fade>
    <ScrollLink
      to="fivetwo"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Five
