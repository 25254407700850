import React from 'react'
import pic04 from '../assets/images/pic04.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const Three = props => (
  <section
    id="three"
    className="spotlight style3 left inactive"
    style={{ backgroundImage: `url(${pic04})` }}
  >
    <span className="image fit main bottom">
      <img src={pic04} alt="" />
    </span>
    <Fade left>
      <div className="content">
        <header>
          <h2>Step 2 Plan:</h2>
          <p>For any stability study not carried out in real-time, you will need to design your experiment with at least two different storage conditions.  The most basic accelerated stability study will include tests carried out at the assigned long-term storage temperature AND at least one higher temperature.  For example, if your reagent is typically stored in the fridge (+4C), then your experiment could include samples stored at +4C and room temperature (+21C).
           </p>
        </header>
        <p className="align-left">
        <h4>Select your temperatures:</h4>
          </p>
          <p className="align-left">
        For any stability study not carried out in real-time, you will need to design your experiment with at least two different storage conditions.  The most basic accelerated stability study will include tests carried out at the assigned long-term storage temperature AND at least one higher temperature.  For example, if your reagent is typically stored in the fridge (+4C), then your experiment could include samples stored at +4C and room temperature (+21C).
          </p>
        <p className="align-left">
        If you are unsure of the optimal storage temperature for your reagent, then include the lowest temperature at which your product can be safely stored.  This will ensure that your data tells you the longest possible shelf life that can assigned.
        </p>
        <p className="align-left">
        The more temperatures you include, the more confident you can be in your result, but be smart about selecting temperatures that are relevant to your reagent.   Do not stray too far from your long-term storage temperature otherwise phenomena that are irrelevant at the storage temperature start to come into play.  For example, ice crystal formation at freezing temperatures or protein denaturation at high temperatures.
        </p>
        <p className="align-left">
        Typical temperatures include -80C, -20C, 4C, 21C, 37C simply because these are what is readily available with standard laboratory equipment.
        </p>

      </div>
    </Fade>
    <ScrollLink
      to="threetwo"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Three
