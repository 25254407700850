import React from 'react'
import pic03 from '../assets/images/pic03.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const Two = props => (
  <section
    id="two"
    className="spotlight style2 right inactive"
    style={{ backgroundImage: `url(${pic03})` }}
  >
    <span className="image fit main">
      <img src={pic03} alt="" />
    </span>
    <Fade right big>
      <div className="content">
        <header>
          <h2>Step-by-Step</h2>
          <p>This page provides the step-by-step experiment and data analysis necessary for calculating your reagent’s shelf life. </p>
        </header>
        <h3>Step 1 Determine Method of Testing</h3>
        <p className="align-left">
        Identify the test you will use to determine the performance of your reagent.  Typically, this will be some sort of experimental readout, for example, **.
          </p>
          <p className="align-left">
        You will need to perform this test over many samples and timepoints, so make sure it is robust – provides sufficient and stable signals.  If the variability in your test is high for replicates, it will be difficult to separate signal changes from the noise of your data.
          </p>


      </div>
    </Fade>
    <ScrollLink
      to="three"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Two
