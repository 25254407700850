import React from 'react'
import pic06 from '../assets/images/pic06.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const Six = props => (
  <section
    id="six"
    className="spotlight style1 bottom inactive"
    style={{ backgroundImage: `url(${pic06})` }}
  >
    <span className="image fit main">
      <img src={pic06} alt="" />
    </span>
    <Fade bottom big>
      <div className="content">
        <div className="container">
          <div className="row gtr-uniform">
            <div className="col-4 col-12-medium">
              <header>
                <h2>Step 5: Go!</h2>
                <p>
                  Run your first performance test on Day 1 of your experiment.  Input your data into the Excel template.  Continue running performance tests on the scheduled days and meticulously recording the data.
                </p>
              </header>

              <p>
                If you observe any nonlinearity in your data or it seems that you have misjudged your expiry date, take the time to adjust your schedule.
              </p>
            </div>
            <div className="col-4 col-12-medium">
            </div>
            <div className="col-4 col-12-medium">
              <header>
                <h2>Step 6: Analysis and Conclusion</h2>
                <p>
                <div className="align-center">
              <div className="div-inline">
              <ul className="actions align-center">
                <li>
                  <a href="/" className="button">
                    Example analysis: [link Excel]
                  </a>
                  </li>
              </ul>
              </div>
             </div>
                </p>
              </header>
              <p>
Consider the places your shelf life knowledge can be used to inform decisions.
Can larger batch sizes be manufactured to reduce repeat manufacturing efforts?
Can your reagent be safely stored at an even colder temperature
          </p>

          </div>
        </div>
        </div>


      </div>
    </Fade>
  </section>
)
export default Six
