import React from 'react'
import pic09 from '../assets/images/pic09.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const Four = props => (
  <section
    id="four"
    className="spotlight style3 right inactive"
    style={{ backgroundImage: `url(${pic09})` }}
  >

    <Fade right big>
      <div className="content">

              <header>
                <h2>Step 3 Gather your resources:</h2>
                <p>
                A good friend of mine once shared some important advice: The best experiment to do is the one you can do.</p>
              </header>
              <p className="align-left">
            Don’t fall into the trap of designing your experiment to be so “complete,” that the level of complexity and cost of resources is beyond what is reasonable for the application.  At the end of a well-run study, you will know more than you did starting out, which is significantly better than having nothing at all.</p>
      </div>

    </Fade>
    <ScrollLink
      to="fourtwo"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)
export default Four
